var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"id":"messageDetails","flat":""}},[_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),(_vm.messages && _vm.messages.length > 0 && _vm.messageHeader)?[_c('v-card-subtitle',{staticClass:"messageDetails_subtitle px-0 pt-0"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('message.messages_list_text'))}})]),_c('v-data-table',{staticClass:"messageDetails_dataGrid",attrs:{"headers":_vm.HeadersNormalized,"items":_vm.messages,"expanded":_vm.expanded,"item-key":"Id","single-expand":"","show-expand":"","items-per-page":_vm.getItemsPerPage(),"footer-props":{'items-per-page-text':_vm.$t('message.message_details_num_per_page'), 'items-per-page-options': [ _vm.getItemsPerPage() ]}},on:{"update:expanded":function($event){_vm.expanded=$event},"item-expanded":_vm.getMessageBody},scopedSlots:_vm._u([(!_vm.newMessage)?{key:"item.read",fn:function(ref){
var item = ref.item;
return [(item.read == 1)?_c('span',[_c('v-icon',{staticClass:"messageDetails_icon_read"},[_vm._v("mdi-checkbox-marked-circle")])],1):_c('span',[_c('v-icon',{staticClass:"messageDetails_icon_unread"},[_vm._v("mdi-alert-circle")])],1)]}}:null,{key:"item.data-table-expand",fn:function(ref){
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('v-icon',{staticClass:"messageDetails_icon_show",on:{"click":function($event){return expand(!isExpanded)}}},[_vm._v("mdi-message")])]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"messageDetails_icon_delete",on:{"click":function($event){return _vm.deleteMessage(item)}}},[_vm._v("mdi-delete")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"0px","box-shadow":"none"},attrs:{"colspan":headers.length}},[_c('v-card',{staticClass:"pa-4",attrs:{"tile":"","flat":""}},[_c('v-card-text',{staticClass:"messageDetails_body_text",domProps:{"innerHTML":_vm._s(item.message_body)}})],1)],1)]}}],null,true)})]:_vm._e(),(!_vm.overlay && (!_vm.messages || _vm.messages.length == 0) && _vm.messageHeader)?_c('div',{staticClass:"messageDetails_no_messages_text",domProps:{"innerHTML":_vm._s(_vm.$t('message.messages_list_no_doc_text'))}}):_vm._e(),_c('Confirmation',{ref:"myConfirmation"}),_c('Snackbar',{ref:"mySnackbar"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }