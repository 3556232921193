<template>
    <v-container class="pt-8 pb-8 pa-0" flat>
        <template v-for="(component, i) in getNormalizedPathname()">
            <component :key="'multi-component-' + i" :is="component" 
                :id="'multiModule-' + i" class="multiModule"></component>
        </template>
    </v-container>
</template>

<script>
import Home from './Home.vue';
import Documents from './Documents.vue';
import Contracts from './Contracts.vue';
import MyProfile from './MyProfile.vue';
import ContactUs from './ContactUs.vue';
import Faq from './Faq.vue';
import Messages from './Messages.vue';
import UserForm from './UserForm.vue';
import ErrorPage from './ErrorPage.vue';
    export default {
        name: 'multiModule',
        components: {
            Home,
            Documents,
            Contracts,
            MyProfile,
            ContactUs,
            Faq,
            Messages,
            UserForm,
            ErrorPage
        },
        data: function () {
            return {
            };
        },
        methods: {
            getNormalizedPathname: function () {
                var components = [];
                var modules = localStorage.getItem("multimodules");
                if (modules.indexOf(',') > -1) {
                    var pathnameSplitted = modules.replace('/','').split(',');
                    pathnameSplitted.forEach(function (item) {
                        switch (item.toLowerCase()) {
                            case 'home':
                                components.push('Home');
                                break;
                            case 'invoices':
                                components.push('Documents');
                                break;
                            case 'contracts':
                                components.push('Contracts');
                                break;
                            case 'modif_data_user':
                                components.push('MyProfile');
                                break;
                            case 'contacts':
                                components.push('ContactUs');
                                break;
                            case 'self':
                                components.push('Faq');
                                break;
                            case 'messages':
                                components.push('Messages');
                                break;
                            case 'userform':
                                components.push('UserForm');
                                break;
                        }
                    });
                }

                if (components.length < 1)
                    components.push('ErrorPage');

                return components;
            }
        }
    };
</script>