<template>
  <v-container v-if="messageHeader" id="messages" class="pt-8 pb-8 pa-0">
    <BannerPage icon="mdi-email-mark-as-unread" :title="$t('message.message_header')"></BannerPage>
    <v-skeleton-loader type="article, article" tile :loading="MessagesPageLoading">
      <v-row class="messages_row">
        <v-col cols="12">
          <v-card tile flat class="border">
            <v-card-text class="messages_text">
                <MessageDetails :message-header="messageHeader" :new-message="false"></MessageDetails>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-skeleton-loader>
  </v-container>
</template>

<script>
import BannerPage from './BannerPage.vue';
import MessageDetails from './MessageDetails.vue';
import { services } from "../scripts/services/serviceBuilder";

export default {
  name: 'messages',
  components: {
    BannerPage,
    MessageDetails
	},
  data: () => ({
    messageHeader: null,
    MessagesPageLoading: true
  }),
  methods:{
    getMessageHeader: function () {
      var that = this;
      services.apiCallerMessage.getHeaders(that.$cod_client.value)
      .then(response => { 
          that.messageHeader = response.data;  
          that.MessagesPageLoading = false;
      })
      .catch(error => {
          console.log(error);
      });
    }
  },
  created () {
    if(this.$cod_client.value) {
        this.getMessageHeader(); 
    } 
    this.$root.$on("codClientChanged", this.getMessageHeader);
  }
}
</script>

<style scoped>
.border{
    border:2px #E8EAF6 solid
}
</style>