<template>
    <v-card id="messageDetails" flat>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>                    
        </v-overlay> 

        <template v-if="messages && messages.length > 0 && messageHeader">

            <v-card-subtitle class="messageDetails_subtitle px-0 pt-0">
                <span v-html="$t('message.messages_list_text')"></span>
            </v-card-subtitle>

            <v-data-table 
                :headers="HeadersNormalized"
                :items="messages"
                :expanded.sync="expanded" 
                item-key="Id"
                single-expand
                show-expand
                class="messageDetails_dataGrid"
                :items-per-page="getItemsPerPage()"
                :footer-props="{'items-per-page-text':$t('message.message_details_num_per_page'), 'items-per-page-options': [ getItemsPerPage() ]}"
                @item-expanded="getMessageBody">
                <template v-if="!newMessage" v-slot:item.read="{ item }">
                    <span v-if="item.read == 1">
                        <v-icon class="messageDetails_icon_read">mdi-checkbox-marked-circle</v-icon>
                    </span>
                    <span v-else>
                        <v-icon class="messageDetails_icon_unread">mdi-alert-circle</v-icon>
                    </span>
                </template>
                <template v-slot:item.data-table-expand="{ expand, isExpanded }">
                    <v-icon class="messageDetails_icon_show" @click="expand(!isExpanded)">mdi-message</v-icon>
                </template>
                <template v-slot:item.delete="{ item }">
                    <v-icon class="messageDetails_icon_delete" @click="deleteMessage(item)">mdi-delete</v-icon>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td style="padding:0px; box-shadow:none" :colspan="headers.length">
                        <v-card class="pa-4" tile flat>
                            <v-card-text class="messageDetails_body_text" v-html="item.message_body"></v-card-text>
                        </v-card> 
                    </td> 
                </template>
            </v-data-table>

        </template>

        <div v-if="!overlay && (!messages || messages.length == 0) && messageHeader"
            class="messageDetails_no_messages_text" v-html="$t('message.messages_list_no_doc_text')">
        </div>

        <Confirmation ref="myConfirmation"></Confirmation>
        <Snackbar ref="mySnackbar"></Snackbar> 
    </v-card>
</template>

<script>
import { services } from "../scripts/services/serviceBuilder";
import Confirmation from './Confirmation.vue';
import Snackbar from './Snackbar.vue';

export default {
    name: 'messageDetails',
    components: {
        Confirmation,
        Snackbar
	},
    data: function () {
         return {
            expanded: [],
            detailHeaders: null,
            checkboxStatus: false,
            messages: null,
            overlay: false
        }      
    },
    computed: {
        HeadersNormalized: function () {
            var headers = [];

            if (!this.newMessage)
                headers.push({
                    text: ' ',
                    sortable: false,
                    value: 'read',
                    class: "secondaryColorBg white--text"
                });

            headers.push({
                text: this.$t('message.messages_header_date'),
                sortable: true,
                value: 'date_start',
                class: "secondaryColorBg white--text",
                width: "110px"
            });
            headers.push({
                text: this.$t('message.messages_header_object'),
                sortable: true,
                value: 'message_object',
                class: "secondaryColorBg white--text",
                width: "70%"
            });
            headers.push({
                text: this.$t('message.messages_header_last_column'),
                sortable: false,
                align: 'center',
                value: 'data-table-expand',
                class: "secondaryColorBg white--text"
            });
            headers.push({
                text: ' ',
                align: 'center',
                sortable: false,
                value: 'delete',
                class: "secondaryColorBg white--text"
            });

            return headers;
        },
    },
    props: {
        messageHeader: null,
        newMessage: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        deleteMessage: async function (item) {
            var that = this;

            if (await that.$refs.myConfirmation.open(
                that.$t('message.message_delete_confirm_title'),
                that.$t('message.message_delete_confirm_text'),
                true, 
                { 'accept': this.$t("message.message_details_delete_accept"), 'decline': this.$t("message.message_details_delete_decline") })
            ) {
                that.overlay = true;

                services.apiCallerMessage.deleteMessage(item.Id)
                .then(response => { 
                    if (response.status == 204) {
                        var index = that.messages.findIndex(x => x.Id == item.Id);
                        if (index > -1)
                            that.messages.splice(index, 1);
                    }
                    else {
                        that.$refs.mySnackbar.open(that.$t("message.message_details_delete_error"), 'warning');
                    }
                    that.overlay = false;
                    that.$refs.mySnackbar.open(that.$t("message.message_details_delete_successfully"), 'success');

                })
                .catch(error => {
                    console.log(error);
                    that.$refs.mySnackbar.open(that.$t("message.message_details_error"));
                    that.overlay = false;
                });
            }
        },
        getItemsPerPage: function () {
            return parseInt(this.messageHeader.messages_list_limit);
        },
        getMessages: function () {
            var that = this;

            that.overlay = true;

            services.apiCallerMessage.getMessages(that.$cod_client.value)
                .then(response => { 
                    that.messages = response.data; 
                    that.overlay = false; 
                })
                .catch(error => {
                    that.overlay = false; 
                    that.$refs.mySnackbar.open(that.$t("message.message_details_get_error"));
                    console.log(error);
                });
        },
        getMessageBody: function ({ item }) {
            var that = this;

            that.overlay = true;

            services.apiCallerMessage.getMessageDetail(item.Id)
            .then(response => { 
                item.message_body = response.data.message_body;  
                item.read = response.data.read;
                that.overlay = false;
            })
            .catch(error => {
                that.overlay = false;
                console.log(error);
                that.$refs.mySnackbar.open(that.$t("message.message_details_get_error"));
            });
        }
    },
    created: function () {
        if(this.$cod_client.value) {
            this.getMessages();
        }

        this.$root.$on("codClientChanged", this.getMessages);
    }
}
</script>
