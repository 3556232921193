<template>
    <v-container class="pt-8 pb-8 pa-0" flat id="myprofile">
        <BannerPage 
            :title="$t('message.anagrafic_change_list_page_header')"
            :subtitle="$t('message.anagrafic_change_list_page_comment')" icon="mdi-account-circle">
        </BannerPage>
        <v-skeleton-loader tile type="article, article" :loading="profilePageLoading">

            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>                    
            </v-overlay>
            <v-form ref="myProfileForm" v-model="valid">
                <v-card v-if="profile" tile class="border" width="100%" flat>
                    <v-card-text class="myprofile_content">
                    <v-row v-for="(group, i) in profile.groups" :key="'group-' + i" class="myprofile_row">
                        <v-col cols="12" sm="6" md="6">
                        <v-card-subtitle v-if="group.label && group.label != ''" class="myprofile_group_label">{{$t('message.' + group.label)}}</v-card-subtitle>
                        <span v-if="group.comment && group.comment != ''" class="myprofile_group_comment">{{$t('message.' + group.comment)}}</span>
                        <template v-for="(field, j) in group.field_list">
                            <v-checkbox v-if="field.format == 'bool'"
                                :key="'field-' + j"
                                class="myprofile_input"
                                v-model="field.value"
                                :label="$t('message.' + field.field_label) + (field.required ? '*' : '')"
                                :hint="$t('message.' + field.comment)"
                                :persistent-hint="field.comment && $t('message.' + field.comment).length > 0 ? true : false"
                                :disabled="field.actions_in_anagrafic == 3">
                            </v-checkbox>
                            <v-select v-else-if="field.format == 'dropdown' || field.format == 'naz' || field.format == 'prov'"
                                :key="'field-' + j"
                                class="myprofile_input"
                                v-model="field.value"
                                :items="field.dropdown"
                                :item-text="field.dropdown.text"
                                :item-value="field.dropdown.value"
                                :label="$t('message.' + field.field_label) + (field.required ? '*' : '')"
                                :hint="$t('message.' + field.comment)"
                                :persistent-hint="field.comment && $t('message.' + field.comment).length > 0 ? true : false"
                                :disabled="field.actions_in_anagrafic == 3"
                                return-object
                                @change="changeDropdown(field)"
                                @click="clickDropdown(field)">
                            </v-select>
                            <v-text-field v-else
                                :key="'field-' + j"
                                class="myprofile_input"
                                v-model="field.value" 
                                :label="$t('message.' + field.field_label) + (field.required ? '*' : '')" 
                                :hint="$t('message.' + field.comment)"
                                :persistent-hint="field.comment && $t('message.' + field.comment).length > 0 ? true : false"
                                :rules="profileRules(field)" 
                                :disabled="field.actions_in_anagrafic == 3"
                                :type="field.format == 'date' ? 'date' : 'text'">
                            </v-text-field> 
                        </template>
                        </v-col>
                    </v-row>
                   
                    <v-btn :disabled="!valid" 
                        class="myprofile_btn primaryColorBg white--text" 
                        @click="checkAndSaveProfile()">
                        <v-icon class="ma-1" v-if="this.keyClokMail == this.originMail && valid" >
                            mdi-exclamation
                        </v-icon> 
                        {{ $t("message.my_profile_save_button") }}
                    </v-btn> 
                    </v-card-text>
                </v-card>  
            </v-form>
        </v-skeleton-loader>
    
        <Confirmation ref="myConfirmation"></Confirmation>
        <Snackbar ref="mySnackbar"></Snackbar> 
    </v-container>
</template>

<script>
import { services } from "../scripts/services/serviceBuilder";
import BannerPage from './BannerPage.vue';
import Snackbar from './Snackbar.vue';
import Confirmation from './Confirmation.vue';


export default {
    name:'MyProfile',
    components:{
        BannerPage,
        Snackbar,
        Confirmation
    },
    data() {
        return {
            profile: {},
            valid: false,
            originMail: null,
            overlay: false,
            keyClokData: null,
            profilePageLoading: true,
            keyClokMail: null,
        }        
    },
    methods:{
        changeDropdown: function(field) {
            var that = this;
            if(field.format == 'naz') {
                for(var group in that.profile.groups) {
                    that.profile.groups[group].field_list.forEach(x => {
                        if (x.referred_field == field.id && x.format == 'prov') {
                        x.value = {};
                        x.dropdown = [];
                        }
                    })
                }
            }    
        }, 
        checkDecimal: function (value) {
            if (value == null || value.toString().length == 0)
                return true;
            return /^\d+(?:[.,]\d+)?$/.test(value) || this.$t("message.my_profile_check_decimal");
        },
        checkEmail: function (value) {
            if (value == null || value.toString().length == 0)
                return true;
            return /^.+@.+\..+$/.test(value) || this.$t("message.my_profile_check_email");
        },
        checkInt: function (value) {
            if (value == null || value.toString().length == 0)
                return true;
            return /^\d+$/.test(value) || this.$t("message.my_profile_check_int");
        },
        checkPhone: function (value) {
            if (value == null || value.toString().length == 0)
                return true;   
            return /^[+]{1}[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s.,\\/0-9]*$/.test(value) || this.$t("message.my_profile_check_phone");
        },
        checkRequired: function (value) {
            if (value == null || value.toString().length == 0)
                return this.$t("message.my_profile_check_text");
            return true;
        },
        checkTextOnly: function (value) {
            if (value == null || value.toString().length == 0)
                return true;
            return /^[a-zA-Z]+$/.test(value) ||  this.$t("message.my_profile_check_text_only");
        },   
        clickDropdown: function(field) {
            var that = this;
            var ref = "";
            if(field.format == 'prov') {
                for(var group in that.profile.groups) {
                that.profile.groups[group].field_list.forEach(x => {
                    if (x.id == field.referred_field) {
                    ref = x.value.value;
                    }
                })
                }
                services.apiCallerRegistration.getDropdownValues('prov', ref)
                .then(res => {
                    field.dropdown = res.data;
                }) 
            }    
        }, 
        formEmail() {
            var that = this;
            var email = null;
            for(var group in that.profile.groups) {
                that.profile.groups[group].field_list.forEach(field => {
                    if (field.field == 'email')
                    {
                        email = field.value;
                    }
                })
            }
            return email;
        },
		getKeycloak: function (node) {
			if (node == null)
				return null;
			if (node.$options.name != "App")
				return this.getKeycloak(node.$parent);
			return node.keycloak;
		},
        getUserInfo: function() {
            var json = {};
            json['cod_client'] = this.$cod_client.value;
            for(var group in this.profile.groups) {
                this.profile.groups[group].field_list.forEach(field => {
                    if(field.format == 'dropdown' || 
                        field.format == 'naz' ||
                        field.format == 'prov') {
                        json[field.field] = field.value.value;
                    }
                    else if(field.format == 'date') {
                        json[field.field] = field.value;
                    }
                    else {
                        json[field.field] = field.value;
                    }
                })
            }

            return json;
        },
        loadProfile: function(codClient) {
            var that = this;
            that.profilePageLoading = true;

            that.profile = Object.assign({},{});
            that.originMail = null;
            services.apiCallerCustomer.profile(codClient)
                .then(res => {
                    //console.log(res.data);
                    that.profile = res.data;
                    for(var group in that.profile.groups) {
                        that.profile.groups[group].field_list.forEach(field => {
                            if (field.field == 'email')
                            {
                                that.originMail = field.value;
                            }
                            if (field.format == 'prov') {
                                field.dropdown = [field.value];
                            }
                            else if(field.format == 'date') {
                                field.value = field.value.substring(0,10);
                            }
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                    that.$refs.mySnackbar.open('Errore di comunicazione.');
                })
                .finally(() => {
                    that.profilePageLoading = false;
                });                    
        },
        checkAndSaveProfile: async function () {
            var that = this;
            that.$refs.mySnackbar.close();
            if (!that.$refs.myProfileForm.validate())
                return;

            if(that.originMail == that.keyClokMail && that.formEmail != that.originMail)
            {
                if (await that.$refs.myConfirmation.open(
                     that.$t("message.my_profile_change_username"),
                     that.$t("message.my_profile_change_username_text"),
                    true, 
                    // { 
                    //     'accept': 'Ok', 
                    //     'decline': 'Annulla'
                    // }
                    { 'accept': this.$t("message.my_profile_change_username_accept"), 'decline': this.$t("message.my_profile_change_username_decline") }


                )) {
                    that.saveProfile();
                }  
            }
            else
            {
                that.saveProfile();
            }
        },  
        saveProfile: function () {
            var that = this;

            this.overlay = true;
            var success = null;
            var message = that.$t("message.my_profile_save_profile_denied");

            services.apiCallerCustomer.saveProfile("profile", this.getUserInfo())
                .then((res) => {
                    if(res.data != null) {
                        success = res.data.success ? 'success' : null;
                        message = res.data.message;

                        if(res.data.username_changed) {
                            var logoutOptions = { redirectUri : window.location.origin };
                            that.keyClokData.logout(logoutOptions);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    that.overlay = false;
                    that.$refs.mySnackbar.open(message, success);
                });
  
        },   
        profileRules: function (field) {
            var rules = [];
            if(field.required) {
                rules.push(this.checkRequired)
            }
            
            //check format
            switch(field.format) {
                case 'textonly':
                rules.push(this.checkTextOnly);
                break;
                case 'int':
                rules.push(this.checkInt);
                break;
                case 'decimal':
                rules.push(this.checkDecimal);
                break;
                case 'email' :
                rules.push(this.checkEmail);
                break;
                case 'phone' :
                rules.push(this.checkPhone);
                break;
                default:
                break;
            }

            return rules;
        },  
    },
    created() {
        this.keyClokData = this.getKeycloak(this.$parent);
        this.keyClokMail = this.keyClokData.idTokenParsed.email;

        if(this.$cod_client.value) {
            this.loadProfile(this.$cod_client.value); 
        } 
        this.$root.$on("codClientChanged", this.loadProfile); 
    },
}
</script>
