<template>
    <v-container  class="pt-8 pb-8 pa-0" flat>
        <BannerPage icon="mdi-frequently-asked-questions " :title="$t('message.faq_header')"></BannerPage>
        <v-skeleton-loader tile type="article, article, article" :loading="faqPageLoading">

            <v-expansion-panels flat>
                <v-expansion-panel class="mt-2 mb-2 border" v-for="(v, k, i) in faqCategories"  :key="'category-' + i">
                    <v-expansion-panel-header class="faqCategories"> {{ v[0].label }} </v-expansion-panel-header>
                    <v-expansion-panel-content>  
                        <v-expansion-panels flat>
                                <v-expansion-panel  class="mt-2 mb-2 border" v-for="(item, i) in v"  :key="'details-' + i">
                                    <v-expansion-panel-header class="faqQuestions">{{ item.question }}</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <div class="faqAnswer" v-html="item.answer"></div>
                                        <v-btn depressed text class="primaryColorTx pa-2 mr-2 " id="faqButton" v-for="(attribute, key, i) in item.attaches" :key="'-' + i"
                                            @click="checkFaq(attribute)">
                                            <v-icon medium class="mr-2" color="red">mdi-file-pdf</v-icon>
                                             {{attribute.fileName}}
                                        </v-btn>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-skeleton-loader>    
        <Snackbar ref="mySnackbar"></Snackbar> 

        <v-dialog v-model="contractDialog" width="60%" :key="dialogKey">
            <v-card>
                <v-card-title>
                    <v-spacer></v-spacer>
                    <v-icon @click="contractDialog = false">mdi-close</v-icon>
                </v-card-title>
                <v-card-text>
                     <Contract :fromFaq="true"
                        @close-contract="closeContract">
                    </Contract>
                </v-card-text>
            </v-card>
        </v-dialog>

    </v-container>
</template>
<script>
import { services } from "../scripts/services/serviceBuilder";
import Snackbar from './Snackbar.vue';
import BannerPage from './BannerPage.vue';
import Contract from "./Contracts.vue";

    export default {
        name: 'faq',
        components: {
            Snackbar,
            BannerPage,
            Contract
        },
        data: function() {
            return {
                faqCategories: null,
                items: null,
                faqPageLoading: true,
                contractDialog: false,
                currentAttribute: null,
                dialogKey: 0
            };
        },
        watch: {
            contractDialog: function(value) {
                if (!value) {
                    this.dialogKey += 1;
                }
            }
        },
        methods: {
            checkFaq: function(attribute) {
                if(attribute.fillByContract == 1) {
                    this.currentAttribute = attribute;
                    this.contractDialog = true;
                }
                else {
                    this.downloadFile(attribute, 0);
                }
            },
            closeContract: function(id) {
                //this.contractDialog = false;
                this.downloadFile(this.currentAttribute, id);
            },
            downloadPdf: function (base64String, attribute) {
                var data = Uint8Array.from(atob(base64String), c => c.charCodeAt(0));
                var blob = new Blob([data], {type: "octet/stream"});

                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = attribute.fileName;
                link.click();
            },
            downloadFile: function(attribute, contractId)
            {
                var that = this;
                services.apiCallerFaq.downloadFaqAttachment(that.$cod_client.value, attribute.id, contractId)
                .then(response =>  {
                    that.downloadPdf(response.data, attribute);
                })               
                .catch(error => {
                that.$refs.mySnackbar.open(that.$t("message.faq_error"));
                    console.log(error);
                })
            },
            groupBy: function (xs, key) {
                return xs.reduce(function(rv, x) {
                    (rv[x[key]] = rv[x[key]] || []).push(x);
                    return rv;
                }, {});
            },
            loadData: function () {
                var that = this;
                this.allFaq = [];
                this.faqPageLoading = true;

                console.log("loadData 1");

                services.apiCallerFaq.listQuestions(this.$locale.value)
                    .then(response => { 
                        that.faqCategories =  that.groupBy(response.data,'faq_category')  
                        that.faqPageLoading = false;
                    })
                    .catch(error => {
                    that.$refs.mySnackbar.open(that.$t("message.faq_error"));
                        console.log(error);
                    });
            },
            reloadData: function(){
                this.loadData();
            }
        },
        created () {
            console.log("created 1");
            this.loadData();
            this.$root.$on("localeChanged", this.reloadData);
        }
    }
</script>


<style>
.ellipsis { text-overflow: ellipsis !important;  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;  }
</style>
